import type { builderComponentSdkFactoryArgs, componentSdkFactoryArgs } from '@wix/thunderbolt-platform-types'
import { BUILDER_COMPONENT_SDK_FACTORY_PARAMS, SERVICES_LOADER } from './moduleNames'
import type { IServices } from './servicesLoader'

export type IBuilderComponentSdkFactoryParams = {
	getBuilderComponentSdkFactoryParams(sdkFactoryParams: componentSdkFactoryArgs): builderComponentSdkFactoryArgs
}

const BuilderComponentSdkFactoryParams = ({ getService }: IServices): IBuilderComponentSdkFactoryParams => {
	return {
		getBuilderComponentSdkFactoryParams: (sdkFactoryParams: componentSdkFactoryArgs) => ({
			getProps: () => sdkFactoryParams.props,
			setProps: sdkFactoryParams.setProps,
			getService,
			legacyApi: sdkFactoryParams,
		}),
	}
}

export default {
	factory: BuilderComponentSdkFactoryParams,
	deps: [SERVICES_LOADER],
	name: BUILDER_COMPONENT_SDK_FACTORY_PARAMS,
}
