import _ from 'lodash'
import { createPromise } from '@wix/thunderbolt-commons'
import type { IComponentSdksManager } from '../types'
import type { IPlatformLogger, IModelsAPI, BuilderComponentsSdkUrls } from '@wix/thunderbolt-symbols'
import type { BootstrapData, BuilderComponentSdks, ComponentSdks, ComponentSdksLoader, CoreSdkLoaders } from '../../types'
import { LOAD_COMPONENT_SDKS_PROMISE, COMPONENT_SDKS_MANAGER, MODELS_API, PLATFORM_LOGGER, BOOTSTRAP_DATA, BUILDER_COMPONENT_SDK_FACTORY_PARAMS } from './moduleNames'
import type { IBuilderComponentSdkFactoryParams } from './builderComponentSdkFactoryParams'
import type { componentSdkFactoryArgs } from '@wix/thunderbolt-platform-types'

const getSdkTypesToLoad = (modelsApi: IModelsAPI) => {
	const compIdToConnections = modelsApi.getCompIdConnections()
	const structureModel = modelsApi.getStructureModel()
	const slots = modelsApi.getSlots()
	const builderComponentIds = _.keys(_.pickBy(structureModel, (comp) => comp.componentType.startsWith('platform.builder')))

	return [
		'PageBackground',
		..._(structureModel)
			.omit(builderComponentIds)
			.transform((sdkTypes, compStructure, compId) => {
				if (compIdToConnections[compId]) {
					sdkTypes[compStructure.componentType] = true
				}

				_.forEach(slots[compId], (slotCompId) => {
					sdkTypes[_.get(structureModel, [slotCompId, 'componentType'])] = true
				})
			}, {} as Record<string, boolean>)
			.keys()
			.value(),
	]
}

const getBuilderComponentsSdks = async (modelsApi: IModelsAPI, builderComponentsSdkUrls: BuilderComponentsSdkUrls): Promise<Record<string, any>> => {
	const structureModel = modelsApi.getStructureModel()
	const builderComponentTypes = _.values(structureModel)
		.map(({ componentType }) => componentType)
		.filter((compType) => compType?.startsWith('platform.builder'))
	const uniqueBuilderComponentTypes = _.uniq(builderComponentTypes)

	return Object.fromEntries(
		await Promise.all(
			uniqueBuilderComponentTypes.map((componentType) => {
				const [appDefinitionId, componentId] = componentType.split('.').pop()?.split('_') || []
				const url = builderComponentsSdkUrls[appDefinitionId][componentId]

				if (!url) {
					return Promise.resolve([componentType, null])
				}

				return import(/* webpackIgnore: true */ url)
					.then((sdk) => [componentType, sdk.default])
					.catch((error) => {
						console.error(`Failed to fetch sdk for componentId: ${componentType} from ${url}`, error)
						return [componentType, null]
					})
			})
		)
	)
}

const ComponentSdksManager = (
	loadComponentSdksPromise: Promise<ComponentSdksLoader>,
	modelsApi: IModelsAPI,
	logger: IPlatformLogger,
	bootstrapData: BootstrapData,
	{ getBuilderComponentSdkFactoryParams }: IBuilderComponentSdkFactoryParams
): IComponentSdksManager => {
	const componentsSdks: ComponentSdks = {}
	const builderComponentsSdks: BuilderComponentSdks = {}
	const { builderComponentsSdkUrls } = bootstrapData
	const sdkTypesToCompTypesMapper: ComponentSdksLoader['sdkTypeToComponentTypes'] = {}
	const { resolver: sdkResolver, promise: sdkPromise } = createPromise()

	const loadCoreComponentSdks = async (compTypes: Array<string>, coreSdksLoaders: CoreSdkLoaders) => {
		const compsPromises = [...compTypes, 'Document', 'RefComponent']
			.filter((type) => coreSdksLoaders[type])
			.map((type) =>
				coreSdksLoaders[type]()
					.then((sdkFactory) => ({ [type]: sdkFactory }))
					.catch((e) => {
						if (e.name !== 'NetworkError') {
							logger.captureError(new Error('could not load core component SDKs from thunderbolt'), {
								groupErrorsBy: 'values',
								tags: { method: 'loadCoreComponentSdks', error: `${e.name}: ${e.message}` },
								extra: { type },
							})
						}
						return {}
					})
			)
		const sdksArray = await Promise.all(compsPromises)
		return Object.assign({}, ...sdksArray)
	}

	return {
		async fetchComponentsSdks(coreSdksLoaders: CoreSdkLoaders) {
			const compTypes = getSdkTypesToLoad(modelsApi)
			logger.interactionStarted('loadComponentSdk')
			const { loadComponentSdks, sdkTypeToComponentTypes } = await loadComponentSdksPromise
			Object.assign(sdkTypesToCompTypesMapper, sdkTypeToComponentTypes || {})
			Object.assign(builderComponentsSdks, await getBuilderComponentsSdks(modelsApi, builderComponentsSdkUrls))
			if (!loadComponentSdks) {
				sdkResolver()
				return
			}
			const componentSdksPromise = loadComponentSdks(compTypes, logger).catch((e) => {
				if (e.name !== 'NetworkError') {
					logger.captureError(new Error('could not load component SDKs from loadComponentSdks function'), {
						groupErrorsBy: 'values',
						tags: { errorType: 'load-component-SDKs-failed', method: 'loadComponentSdks', error: `${e.name}: ${e.message}` },
						extra: { compTypes, componentsRegistry: bootstrapData.platformEnvData.componentsRegistry, ...(e.extraParams ? e.extraParams : {}) },
					})
				}
				return {}
			})
			const [coreSdks, sdks] = await Promise.all([loadCoreComponentSdks(compTypes, coreSdksLoaders), componentSdksPromise]).catch(() => [])
			Object.assign(componentsSdks, sdks, coreSdks)
			sdkResolver()
			logger.interactionEnded('loadComponentSdk')
		},
		waitForSdksToLoad() {
			return sdkPromise
		},
		getComponentSdkFactory(compType) {
			if (componentsSdks[compType]) {
				return componentsSdks[compType]
			}

			const builderComponentSdk = builderComponentsSdks[compType]

			if (!builderComponentSdk) {
				logger.captureError(new Error('could not find component SDK'), {
					groupErrorsBy: 'values',
					tags: { method: 'loadComponentSdks', compType },
				})
				return
			}

			return (sdkFactoryParams: componentSdkFactoryArgs) => builderComponentSdk(getBuilderComponentSdkFactoryParams(sdkFactoryParams))
		},
		getSdkTypeToComponentTypes(sdkType: string) {
			return sdkTypesToCompTypesMapper[sdkType] || [sdkType]
		},
	}
}

export default {
	factory: ComponentSdksManager,
	deps: [LOAD_COMPONENT_SDKS_PROMISE, MODELS_API, PLATFORM_LOGGER, BOOTSTRAP_DATA, BUILDER_COMPONENT_SDK_FACTORY_PARAMS],
	name: COMPONENT_SDKS_MANAGER,
}
